import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { LANDIN_CONFIG } from 'src/app/app.config';
import { logoutUser } from 'src/app/auth/ngrx/auth.actions';
import { State } from 'src/app/reducers';
import { DeleteFeedbackComponent } from 'src/app/settings/account/delete-profile/delete-feedback/delete-feedback.component';
import * as BillingActions from 'src/app/settings/billing/ngrx/billing.actions';
import { ConfirmDialogComponent } from 'src/app/shared/Components/dialogs/confirm-dialog/confirm-dialog.component';
import { SimpleDialogComponent } from 'src/app/shared/Components/dialogs/simple-dialog/simple-dialog.component';
import { PreviewComponent } from 'src/app/shared/Components/pdf-preview/pdf-preview.component';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { UserRequest } from 'src/app/shared/Models/feedback';
import { DeepPartial, Results } from 'src/app/shared/Models/generics';
import { ResetPassword } from 'src/app/shared/Models/models';
import { SimpleUser, User } from 'src/app/shared/Models/user';
import {
  handleHttpError,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import * as SharedActions from 'src/app/shared/ngrx/shared.actions';
import { ConfigService } from 'src/app/shared/Services/config/config.service';
import { FileService } from 'src/app/shared/Services/files/files.service';
import { GenericsService } from 'src/app/shared/Services/generics/generics.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { restrictTemplates } from 'src/app/templates/ngrx/templates.actions';
import { de, enUS, es, fr, it } from 'date-fns/locale';
import { cloneDeep } from 'lodash-es';
import { EMPTY } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { StatusService } from '../status.service';
import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';

@Injectable()
export class UserEffects {
  patchUserFetchPlansModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserActions.patchUserFetchPlansModules,
        UserActions.patchUserBusinessDetailsFetchPlans,
      ),
      switchMap(({ user }) => {
        const cid = UtilsService.GEN_CID;
        return [
          UserActions.patchUser({ user, cid }),
          UserActions.countryChanged({ cid }),
        ];
      }),
    ),
  );

  fetchPlansAndModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.countryChanged),
      switchMap(({ cid }) =>
        this.actions$.pipe(
          ofType(UserActions.setUser),
          filter((action) => action.cid === cid),
          first(),
        ),
      ),
      switchMap(() => [
        BillingActions.fetchPlans({
          params: { trial: false, recurring: true },
        }),
        SharedActions.fetchUserModules(),
        BillingActions.fetchSubscription(),
      ]),
    ),
  );

  fetchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchUser),
      switchMap(() =>
        this.genericsService.get<User>(this.configService.userCurrent).pipe(
          mergeMap((user) => {
            if (!isDevMode()) Sentry.setUser({ email: user.email });
            return [
              SharedActions.setInterfaceLang({
                lang: user.settings.language,
              }),
              UserActions.setUser({ user, cid: UtilsService.GEN_CID }),
              UserActions.handleStatusMessages({ user }),
              restrictTemplates({ templates: [] }),
              SharedActions.fetchUserModules(),
            ];
          }),
          catchError((error) => {
            if ([500, 502].includes(error.status)) {
              this.router.navigate([`/server-error`], {
                skipLocationChange: true,
              });
            }
            return [handleHttpError({ error })];
          }),
        ),
      ),
    ),
  );

  patchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.patchUser),
      withLatestFrom(this.store.pipe(select(UserSelectors.selectUser))),
      switchMap(([{ user: patchUserData, cid, onFulfilled }, user]) =>
        this.genericsService
          .patch<DeepPartial<User>, User>(user.url, patchUserData)
          .pipe(
            switchMap((user) => {
              onFulfilled?.();
              return [UserActions.setUser({ user, cid })];
            }),
            catchError((error: HttpErrorResponse) => [
              handleHttpError({ error }),
              UserActions.patchUserError({ error, cid }),
            ]),
          ),
      ),
    ),
  );

  uploadUserLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.uploadUserLogo),
      withLatestFrom(this.store.pipe(select(UserSelectors.selectUser))),
      switchMap(([{ file, itemAlias, cid }, user]) =>
        this.fileService.uploadFile(user.url, file, itemAlias, 'PATCH').pipe(
          switchMap((user: User) => [UserActions.setUser({ user, cid })]),
          catchError((error: HttpErrorResponse) => [
            handleHttpError({ error }),
            UserActions.patchUserError({ error, cid }),
          ]),
        ),
      ),
    ),
  );

  setUserStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.setUser),
        map(({ user }) => {}),
      ),
    { dispatch: false },
  );

  parseUserError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.parseUserError),
      map(({ error }) => {
        const parsedError: Partial<User> = error?.error || error;
        if (parsedError.profile) this.router.navigate(['settings', 'profile']);
        return UserActions.setUserError({ error });
      }),
    ),
  );

  emailPreferencesChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.emailPreferencesChanged),
      map(({ newPreferences }) => {
        return UserActions.patchUser({ user: newPreferences });
      }),
    ),
  );

  emailChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeEmail),
      switchMap(({ email }) => {
        const cid = UtilsService.GEN_CID;
        return [
          UserActions.patchUser({ user: { email }, cid }),
          UserActions.emailChanged({ cid }),
        ];
      }),
    ),
  );

  showEmailChangedDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.emailChanged, UserActions.patchUserError),
        switchMap(({ type, cid }) =>
          this.actions$.pipe(
            ofType(UserActions.setUser),
            filter((action) => action.cid === cid),
            first(),
            map(() => type),
          ),
        ),
        tap((type) => {
          const dialog = this.dialog.open(SimpleDialogComponent);
          const instance = dialog.componentInstance;
          const key = 'settings.account.email';
          instance.text =
            type === UserActions.patchUserError.type
              ? this.transloco.translate(`${key}.error`)
              : this.transloco.translate(`${key}.success`);
        }),
      ),
    { dispatch: false },
  );

  changeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeLanguage),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.transloco.selectTranslateObject('shared.dialogs.change-languages'),
        this.transloco.selectTranslateObject('shared.languages'),
      ),
      switchMap(([{ language }, user, { content }, langs]) => {
        const dialog = this.dialog.open(SimpleDialogComponent);
        const instance = dialog.componentInstance;
        instance.cancelable = true;
        instance.text = `${content} ${langs?.[language]}`;
        return dialog.afterClosed().pipe(
          switchMap((answer) => {
            if (answer) {
              const newUser = cloneDeep(user);
              newUser.settings.language = language;
              setTimeout(() => {
                this.store.dispatch(
                  UserActions.handleStatusMessages({ user: newUser }),
                );
              }, 400);
              return [
                UserActions.patchUser({
                  user: {
                    settings: { language },
                  },
                  cid: UtilsService.GEN_CID,
                  onFulfilled: () => {
                    this.store.dispatch(
                      showSnackbarMessage({
                        message: this.transloco.translate(
                          'shared.messages.changes-saved',
                        ),
                        snackClass: 'success',
                      }),
                    );
                  },
                }),
              ];
            }
            return EMPTY;
          }),
        );
      }),
    ),
  );

  userLanguageChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.setUser),
        map(({ user }) => ({
          lang: user?.settings?.language,
          cat: user?.category,
        })),
        distinctUntilChanged(
          ({ lang: pLang, cat: pCat }, { lang: nLang, cat: nCat }) => {
            return pLang === nLang && pCat === nCat;
          },
        ),
        tap(({ lang, cat }) => {
          if (!lang) return undefined;
          this.transloco.setActiveLang(lang);
          this.dateAdapter.setLocale(this.localeMap[lang]);
          if (!cat) return undefined;
          this.transloco
            .selectTranslation(lang)
            .pipe(
              take(1),
              tap(async () => {
                const translationToMerge = await this.utils.checkIfExists(
                  `assets/i18n/categories/${lang}_${cat}.json`,
                );
                if (translationToMerge && Object.keys(translationToMerge)) {
                  this.transloco.setTranslation(translationToMerge, lang, {
                    merge: true,
                  });
                }
              }),
            )
            .subscribe();
        }),
      ),
    { dispatch: false },
  );

  changeCurrency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeCurrency),
      switchMap(({ currency }) => {
        const cid = UtilsService.GEN_CID;
        return [
          UserActions.currencyChanged({ cid }),
          UserActions.patchUser({
            user: {
              settings: {
                currency,
              },
            },
            cid,
          }),
        ];
      }),
    ),
  );

  currencyChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.currencyChanged),
        switchMap(({ cid }) =>
          this.actions$.pipe(
            ofType(UserActions.setUser),
            filter((action) => action.cid === cid),
            first(),
          ),
        ),
        withLatestFrom(
          this.transloco.selectTranslate(
            'shared.dialogs.change-currency.content',
          ),
        ),
        tap(([, translation]) => {
          this.dialog.open(SimpleDialogComponent).componentInstance.text =
            translation;
        }),
      ),
    { dispatch: false },
  );

  openUserDataPdf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.openUserDataPdf),
        withLatestFrom(this.store.select(UserSelectors.selectUser)),
        map(([, user]) => user.user_data_pdf),
        tap((url) => {
          const pdfPreview = this.dialog.open(PreviewComponent, {
            autoFocus: false,
            width: '890px',
            maxWidth: '90vw',
            height: '95%',
          });
          const instance = pdfPreview.componentInstance;
          instance.action = '';
          instance.url = url;
        }),
      ),
    { dispatch: false },
  );

  changeUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeUnits),
      map(({ units }) =>
        UserActions.patchUser({
          user: {
            accountsettings: units,
          },
          onFulfilled: () => {
            this.store.dispatch(
              showSnackbarMessage({
                message: this.transloco.translate(
                  'shared.messages.changes-saved',
                ),
                snackClass: 'success',
              }),
            );
          },
        }),
      ),
    ),
  );

  changeNutritionValueUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeNutritionValueUnits),
      map(({ enabled_nutrition_values }) =>
        UserActions.patchUser({
          user: {
            accountsettings: {
              enabled_nutrition_values,
            },
          },
          onFulfilled: () => {
            this.store.dispatch(
              showSnackbarMessage({
                message: this.transloco.translate(
                  'shared.messages.changes-saved',
                ),
                snackClass: 'success',
              }),
            );
          },
        }),
      ),
    ),
  );

  changeSalesTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeSalesTax),
      map(({ vat_rate, vat_rate_full }) =>
        UserActions.patchUser({
          user: {
            accountsettings: {
              vat_rate,
              vat_rate_full,
            },
          },
          onFulfilled: () => {
            this.store.dispatch(
              showSnackbarMessage({
                message: this.transloco.translate(
                  'shared.messages.changes-saved',
                ),
                snackClass: 'success',
              }),
            );
          },
        }),
      ),
    ),
  );

  deleteAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteAccount),
      withLatestFrom(this.store.select(UserSelectors.selectUser)),
      switchMap(([, user]) => {
        const dialog = this.dialog.open(ConfirmDialogComponent);
        const instance: ConfirmDialogComponent = dialog.componentInstance;
        instance.title = this.transloco.translate(
          'settings.account.delete.confirm.title',
        );
        instance.confirmMessage = this.transloco.translate(
          'settings.account.delete.confirm.actions.delete',
        );
        instance.cancelMessage = this.transloco.translate(
          'shared.buttons.cancel',
        );
        instance.warning = true;
        return dialog.afterClosed().pipe(
          filter((v) => !!v),
          switchMap(() =>
            this.genericsService.delete(user.url).pipe(
              switchMap(() => {
                const dialog = this.dialog.open(DeleteFeedbackComponent);
                this.utils.feedbackSent.subscribe(() => {
                  dialog.close();
                  this.utils.feedbackSent.unsubscribe();
                  this.store.dispatch(
                    logoutUser({
                      redirectUrl: LANDIN_CONFIG.value,
                      relativeRedirect: false,
                    }),
                  );
                });
                return EMPTY;
              }),
            ),
          ),
          catchError((error) => [handleHttpError({ error })]),
        );
      }),
    ),
  );

  changeInvoicingEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeInvoicingEmail),
      switchMap(({ invoicing_email }) => {
        return [
          UserActions.patchUser({
            user: { accountsettings: { invoicing_email } },
          }),
        ];
      }),
    ),
  );

  getOrganisationUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchOrganisationUsers),
      switchMap(({ params }) =>
        this.genericsService
          .get<Results<SimpleUser>>(this.configService.users, params)
          .pipe(
            mergeMap((organisationUsers) => [
              UserActions.setOrganisationUsers({
                payload: organisationUsers.results,
              }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changePassword),
      switchMap(({ passwordData }) =>
        this.genericsService
          .post<
            ResetPassword,
            { detail: string }
          >(this.configService.resetPasswordChange, passwordData, UtilsService.createLanguageHeader(this.transloco.getActiveLang() as InterfaceLanguage))
          .pipe(
            switchMap((res) => {
              if (!res) return EMPTY;
              const dialog = this.dialog.open(SimpleDialogComponent);
              const instance = dialog.componentInstance;
              instance.text = this.transloco.translate(
                'settings.account.password.success',
              );
              return EMPTY;
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  leaveFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.leaveFeedback),
      withLatestFrom(this.store.pipe(select(UserSelectors.selectUser))),
      switchMap(([{ email, message, rating }, user]) =>
        this.genericsService
          .post<UserRequest, {}>(this.configService.requests, {
            email: email ?? user?.email,
            language: user?.settings.language,
            message: `${rating} rating\n${message}`,
            name: `${user.first_name} ${user.last_name}`,
            type: 'feedback',
          })
          .pipe(
            switchMap(() => {
              const dialog = this.dialog.open(SimpleDialogComponent);
              const instance = dialog.componentInstance;
              instance.text = this.transloco.translate(
                'shared.feedback.success.title',
              );
              dialog
                .afterClosed()
                .subscribe(() => this.utils.feedbackSent.next());
              return EMPTY;
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  handleStatusMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.handleStatusMessages),
      switchMap(({ user }) => {
        return [
          UserActions.handleAccountMessages({ user }),
          UserActions.handleMenuLimitMessages({ user }),
          UserActions.handleTemplateLimitMessages({ user }),
        ];
      }),
    ),
  );

  handleAccountMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.handleAccountMessages),
      switchMap(({ user }) =>
        this.statusService.handleAccountStatus(user).pipe(
          mergeMap((status) => [UserActions.setStatusMain({ main: status })]),
          catchError((error) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  handleMenuLimitMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.handleMenuLimitMessages),
      switchMap(({ user }) =>
        this.statusService.handleMenuLimits(user).pipe(
          mergeMap((statusMessage) => [
            UserActions.setStatusMenus({ menus: statusMessage }),
          ]),
          catchError((error) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  handleTemplateLimitMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.handleTemplateLimitMessages),
      switchMap(({ user }) =>
        this.statusService.handleTemplateLimits(user).pipe(
          mergeMap((statusMessage) => [
            UserActions.setStatusTemplates({ templates: statusMessage }),
          ]),
          catchError((error) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private dialog: MatDialog,
    private transloco: TranslocoService,
    private utils: UtilsService,
    private dateAdapter: DateAdapter<Date>,
    private fileService: FileService,
    private genericsService: GenericsService,
    private configService: ConfigService,
    private statusService: StatusService,
  ) {}

  localeMap = {
    en: enUS,
    de: de,
    fr: fr,
    es: es,
    it: it,
  };
}
