import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { de, enUS, es, fr, it } from 'date-fns/locale';
import { SUPPORT_EMAIL } from 'src/app/app.config';
import {
  clearData as clearDashboardData,
  setUserOnboardingTemplates,
} from 'src/app/dashboard/ngrx/dashboard.actions';
import { State } from 'src/app/reducers';
import { SimpleDialogComponent } from 'src/app/shared/Components/dialogs/simple-dialog/simple-dialog.component';
import { SlidedownBarComponent } from 'src/app/shared/Components/slidedown-bar/slidedown-bar.component';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  AcceptInvitationForm,
  EmailVerification,
  LoginForm,
  RegistrationForm,
} from 'src/app/shared/Models/authentication';
import { UserRequest } from 'src/app/shared/Models/feedback';
import { Results } from 'src/app/shared/Models/generics';
import { Invitee } from 'src/app/shared/Models/invitee';
import { EmailConfirmation, ResetPassword } from 'src/app/shared/Models/models';
import {
  TemplatesRequest,
  UserOnboardingTemplate,
} from 'src/app/shared/Models/onboarding_template';
import { Affiliate, EmailPreferences } from 'src/app/shared/Models/user';
import {
  clearData as clearSharedData,
  handleHttpError,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import { ConfigService } from 'src/app/shared/Services/config/config.service';
import { GenericsService } from 'src/app/shared/Services/generics/generics.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { fetchUser, patchUser } from 'src/app/shared/user/ngrx/user.actions';
import { selectUser } from 'src/app/shared/user/ngrx/user.selectors';
import { EMPTY } from 'rxjs';
import {
  catchError,
  delay,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AuthenticationService } from '../authentication.service';
import * as AuthActions from './auth.actions';
import { selectNextPage } from './auth.selectors';

@Injectable()
export class AuthEffects {
  afterEffectsInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      mergeMap(() => {
        const isLoggedIn = !!this.authService.getToken();
        return [
          AuthActions.setLoggedIn({ isLoggedIn }),
          ...(isLoggedIn ? [fetchUser()] : []),
        ];
      }),
      catchError(() => EMPTY),
    ),
  );

  checkEmailRemotely$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.checkEmailRemotely),
      switchMap(({ email }) =>
        this.genericsService
          .post<
            { email: string },
            EmailVerification
          >(this.configService.emailVerification, { email })
          .pipe(
            switchMap((verificationData) => [
              AuthActions.setExistingEmailError({ error: false }),
              AuthActions.setEmailRemoteCheck({ verificationData }),
            ]),
            catchError((error) => {
              if (error.status === 409) {
                this.store.dispatch(
                  AuthActions.setExistingEmailError({ error: true }),
                );
              }
              return [handleHttpError({ error })];
            }),
          ),
      ),
    ),
  );

  completeSetup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.completeSetup),
      withLatestFrom(this.store.select(selectUser)),
      switchMap(([, user]) =>
        this.genericsService.post(`${user.url}complete_setup/`, {}).pipe(
          mergeMap(() => [
            patchUser({
              user: { status: { completed_setup: true } },
              cid: UtilsService.GEN_CID,
            }),
          ]),
        ),
      ),
      catchError((error) => [handleHttpError({ error })]),
    ),
  );

  confirmEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.confirmEmail),
      switchMap(({ key }) =>
        this.genericsService
          .post<
            EmailConfirmation,
            { key?: string }
          >(this.configService.confirmEmail, { key })
          .pipe(
            tap((resp) => {
              this.store.dispatch(AuthActions.notifyEmailConfirmationSuccess());
              this.store.dispatch(
                AuthActions.setEmailVerified({ success: true }),
              );
              if (resp.key) {
                this.authService.setLogin(resp.key);
                this.store.dispatch(
                  AuthActions.setLoggedIn({ isLoggedIn: true }),
                );
              }
            }),
            delay(3000),
            mergeMap(() => [AuthActions.redirectAfterLogin({})]),
            catchError((error) => [
              AuthActions.setEmailVerified({ success: false }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  fetchAffiliates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.fetchAffiliates),
      switchMap(({ code }) =>
        this.genericsService
          .get<Results<Affiliate>>(this.configService.affiliates, { code })
          .pipe(
            mergeMap((affiliates) =>
              affiliates.count
                ? [
                    AuthActions.setAffiliates({
                      affiliates: affiliates.results,
                    }),
                  ]
                : [AuthActions.setAffiliatesError({ error: true })],
            ),
            catchError((error) => [
              AuthActions.setAffiliatesError({ error: true }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginUser),
      tap(() => {
        this.store.dispatch(
          AuthActions.setLoginSpinnerState({ loading: true }),
        );
      }),
      switchMap(({ loginFormData }) =>
        this.genericsService
          .post<
            LoginForm,
            any
          >(this.configService.login, loginFormData, null, UtilsService.createLanguageHeader(this.transloco.getActiveLang() as InterfaceLanguage))
          .pipe(
            mergeMap((resp) => {
              this.authService.setLogin(resp.key);
              return [
                AuthActions.setLoggedIn({ isLoggedIn: true }),
                AuthActions.notifyLoginSuccess(),
                fetchUser(),
                AuthActions.setLoginSpinnerState({ loading: false }),
                // FIXME: redirect to onboarding steps if not yet completed
                AuthActions.redirectAfterLogin({ email: loginFormData.email }),
              ];
            }),
            catchError((error) => [
              AuthActions.setLoginSpinnerState({ loading: false }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  loginWithMagicLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithMagicLink),
      switchMap(({ token }) => {
        this.authService.setLogin(token);
        return [
          AuthActions.setLoggedIn({ isLoggedIn: true }),
          AuthActions.setMagicLink({ isMagicLink: true }),
          fetchUser(),
          AuthActions.redirectAfterLogin({}),
        ];
      }),
    ),
  );

  loginWithToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithToken),
      switchMap(({ token }) => {
        this.authService.setLogin(token);
        return [
          AuthActions.setLoggedIn({ isLoggedIn: true }),
          fetchUser(),
          AuthActions.redirectAfterLogin({}),
        ];
      }),
    ),
  );

  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutUser),
      switchMap(({ redirectUrl, relativeRedirect }) =>
        this.genericsService.post<{}, {}>(this.configService.logout, {}).pipe(
          mergeMap(() => {
            this.authService.setLogout();
            return [
              AuthActions.setLoggedIn({ isLoggedIn: false }),
              AuthActions.redirectAfterLogout({
                url: redirectUrl,
                relative: relativeRedirect,
              }),
              clearSharedData(),
              clearDashboardData(),
            ];
          }),
          catchError((error) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  reactivateTrial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.reactivateTrial),
      switchMap(({ uid, token }) =>
        this.genericsService
          .post<
            {},
            { key?: string }
          >(this.configService.reactivateTrial + `${uid}/${token}/`, {})
          .pipe(
            tap((resp) => {
              this.store.dispatch(
                AuthActions.setReactivationDone({ done: true }),
              );
              if (resp.key) {
                this.authService.setLogin(resp.key);
                this.store.dispatch(
                  AuthActions.setLoggedIn({ isLoggedIn: true }),
                );
              }
            }),
            mergeMap(() => [
              AuthActions.setReactivationRestoreError({ message: null }),
            ]),
            delay(3000),
            mergeMap(() => [AuthActions.redirectAfterLogin({})]),
            catchError((error) => [
              AuthActions.setReactivationRestoreError({
                message: error?.error?.error,
              }),
              AuthActions.setEmailVerified({ success: false }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  redirectAfterLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.redirectAfterLogin),
      withLatestFrom(this.store.select(selectNextPage)),
      switchMap(([{ email }, nextPage]) => {
        if (
          email &&
          nextPage &&
          nextPage.includes('/email_preferences/') &&
          !nextPage.toLowerCase().includes(email.toLowerCase())
        ) {
          this.router.navigate(['settings', 'account']);
        } else if (nextPage) {
          this.router.navigateByUrl(nextPage);
        } else {
          this.router.navigate(['dashboard']);
        }
        return [AuthActions.setNextPage({ nextPage: null })];
      }),
    ),
  );

  redirectAfterLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.redirectAfterLogout),
      tap(({ url, relative }) => {
        if (url && relative) {
          this.router.navigateByUrl(url);
        } else if (url) {
          window.open(url, '_self');
        } else {
          this.router.navigateByUrl('login');
        }
      }),
      switchMap(() => [AuthActions.setNextPage({ nextPage: null })]),
    ),
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.register),
      tap(() => {
        this.store.dispatch(
          AuthActions.setRegisterButtonState({ loading: true }),
        );
      }),
      switchMap(({ data, lang }) =>
        this.genericsService
          .post<RegistrationForm, { key: string }>(
            this.configService.registration,
            {
              ...data,
              language:
                lang || (this.transloco.getActiveLang() as InterfaceLanguage),
            },
            {},
            {
              ...UtilsService.createLanguageHeader(
                this.transloco.getActiveLang() as InterfaceLanguage,
              ),
              observe: 'response',
            },
          )
          .pipe(
            mergeMap((resp) => {
              this.authService.setLogin(resp.key);
              return [
                AuthActions.notifyRegistrationSuccess(),
                AuthActions.setLoggedIn({ isLoggedIn: true }),
                AuthActions.setNextPage({ nextPage: `registration/1` }),
                fetchUser(),
                AuthActions.setRegisterButtonState({ loading: false }),
                AuthActions.redirectAfterLogin({}),
              ];
            }),
            catchError((error) => [
              AuthActions.setRegisterButtonState({ loading: false }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  requestNewContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.requestNewContent),
      switchMap(({ message, content, user }) =>
        this.genericsService
          .post<UserRequest, {}>(this.configService.requests, {
            type: content,
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            language: user.settings.language,
            message,
          })
          .pipe(mergeMap(() => EMPTY)),
      ),
      catchError((error) => [handleHttpError({ error })]),
    ),
  );

  resendEmailConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resendEmailConfirmation),
      withLatestFrom(this.store.select(selectUser)),
      switchMap(([, user]) =>
        this.genericsService
          .post<
            {},
            { message: string }
          >(this.configService.resendEmailConfirmation, {})
          .pipe(
            mergeMap(() => {
              const successDialog = this.dialog.open(SimpleDialogComponent);
              const instance = successDialog.componentInstance;
              instance.link = `mailto:${SUPPORT_EMAIL.value}?subject=Email%20verification%20not%20received&body=User%20ID:%20${user.id}&&reply-to=${user.email}`;
              instance.linkText = this.emailLink;
              instance.text = `${this.emailMessage}`;
              instance.cancelable = false;
              return EMPTY;
            }),
            catchError((error) => {
              if (error.status === 409) return [fetchUser()];
              return [handleHttpError({ error })];
            }),
          ),
      ),
    ),
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      tap(() => {
        this.store.dispatch(
          AuthActions.setResetPasswordButtonState({ loading: true }),
        );
      }),
      switchMap(({ email }) =>
        this.genericsService
          .post<
            { email: string },
            { detail: string }
          >(this.configService.resetPassword, { email }, {}, UtilsService.createLanguageHeader(this.transloco.getActiveLang() as InterfaceLanguage))
          .pipe(
            mergeMap((resp) => [
              AuthActions.setResetPasswordButtonState({ loading: false }),
              showSnackbarMessage({ message: resp.detail }),
            ]),
            catchError((error) => [
              AuthActions.setResetPasswordButtonState({ loading: false }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  resetPasswordConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPasswordConfirm),
      tap(() => {
        this.store.dispatch(
          AuthActions.setResetPasswordButtonState({ loading: true }),
        );
      }),
      switchMap(({ resetPassword }) =>
        this.genericsService
          .post<
            ResetPassword,
            { detail: string; key?: string }
          >(this.configService.resetPasswordConfirm, resetPassword, {}, UtilsService.createLanguageHeader(this.transloco.getActiveLang() as InterfaceLanguage))
          .pipe(
            tap((resp) => {
              if (resp.key) {
                this.authService.setLogin(resp.key);
                this.store.dispatch(
                  AuthActions.setLoggedIn({ isLoggedIn: true }),
                );
              }
            }),
            mergeMap((resp) => [
              showSnackbarMessage({ message: resp.detail }),
              AuthActions.setResetPasswordButtonState({ loading: false }),
              fetchUser(),
              AuthActions.redirectAfterLogin({}),
            ]),
            catchError((error) => [
              AuthActions.setResetPasswordButtonState({ loading: false }),
              handleHttpError({ error, forceSnackbar: true }),
            ]),
          ),
      ),
    ),
  );

  restoreAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.restoreAccount),
      switchMap(({ uid, token }) =>
        this.genericsService
          .post<
            {},
            { key?: string }
          >(this.configService.restoreAccount + `${uid}/${token}/`, {})
          .pipe(
            tap((resp) => {
              this.store.dispatch(
                AuthActions.setReactivationDone({ done: true }),
              );
              if (resp.key) {
                this.authService.setLogin(resp.key);
                this.store.dispatch(
                  AuthActions.setLoggedIn({ isLoggedIn: true }),
                );
              }
            }),
            mergeMap(() => [
              AuthActions.setReactivationRestoreError({ message: null }),
            ]),
            delay(3000),
            mergeMap(() => [fetchUser(), AuthActions.redirectAfterLogin({})]),
            catchError((error) => [
              AuthActions.setReactivationRestoreError({
                message: error?.error?.error,
              }),
              AuthActions.setEmailVerified({ success: false }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  sendOnboardingTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.sendOnboardingTemplates),
      switchMap(({ data }) =>
        this.genericsService
          .post<
            TemplatesRequest,
            UserOnboardingTemplate[]
          >(this.configService.selectMultipleTemplates, data)
          .pipe(
            mergeMap((templates: UserOnboardingTemplate[]) => [
              setUserOnboardingTemplates({ payload: templates }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  updateEmailPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateEmailPreferences),
      switchMap(({ data, email, token }) =>
        this.genericsService
          .post<
            EmailPreferences,
            {}
          >(`${this.configService.emailPreferences}${email}/${token}/`, data)
          .pipe(
            mergeMap(() => {
              this.snackBar.openFromComponent(SlidedownBarComponent, {
                duration: 10000,
              });
              return EMPTY;
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  acceptInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.acceptInvitation),
      tap(() => {
        this.store.dispatch(AuthActions.setAcceptButton({ loading: true }));
      }),
      switchMap(({ data }) =>
        this.genericsService
          .post<Partial<AcceptInvitationForm>, { key: string }>(
            this.configService.invitations + 'register_invitee/',
            data,
            {},
            {
              ...UtilsService.createLanguageHeader(
                this.transloco.getActiveLang() as InterfaceLanguage,
              ),
              observe: 'response',
            },
          )
          .pipe(
            mergeMap((resp) => [
              AuthActions.notifyAcceptInvitationSuccess(),
              AuthActions.loginWithToken({ token: resp.key }),
            ]),
            catchError((error) => [
              AuthActions.setAcceptButton({ loading: false }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  checkInvitationKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.checkInvitationKey),
      switchMap(({ key }) =>
        this.genericsService
          .post<
            { key: string },
            Invitee
          >(this.configService.invitations + 'accept_invitation/', { key })
          .pipe(
            mergeMap((invitee: Invitee) => {
              return [
                AuthActions.setInvitee({ invitee: invitee }),
                AuthActions.setInviteeSpinner({ loading: false }),
              ];
            }),
            catchError((error) => [
              AuthActions.setInviteeSpinner({ loading: false }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private configService: ConfigService,
    private dialog: MatDialog,
    private genericsService: GenericsService,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<State>,
    private transloco: TranslocoService,
    private utils: UtilsService,
  ) {
    this.utils.getTranslationObject(
      'shared.dialogs.resend-email',
      ({ message, link }) => {
        this.emailMessage = message;
        this.emailLink = link;
      },
    );
  }

  emailMessage: string;
  emailLink: string;
  localeMap = {
    en: enUS,
    de: de,
    fr: fr,
    es: es,
    it: it,
  };
}
