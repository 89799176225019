<div class="wrapper">
  @if (ingredients !== null) {
    <div class="headers" [class.narrow]="showNetQuantity">
      <span class="headers-name">{{
        "recipes.content.ingredients.headers.name" | transloco
      }}</span>
      <div class="headers-value" [class.extra]="!showNetQuantity">
        {{ "recipes.content.ingredients.headers.quantity" | transloco }}
      </div>
      @if (showNetQuantity) {
        <div class="headers-value">
          {{ "recipes.content.ingredients.headers.net-quantity" | transloco }}
        </div>
      }
      @if (showNetQuantity) {
        <div class="headers-value weight-loss">
          {{ "recipes.content.ingredients.headers.weight" | transloco }}
        </div>
      }
      @if (showNetQuantity) {
        <div class="headers-value weight-loss">
          {{ "recipes.content.ingredients.headers.weight" | transloco }} (%)
        </div>
      }
      <div class="headers-value unit" [class.extra]="!showNetQuantity">
        {{ "recipes.content.ingredients.headers.unit" | transloco }}
      </div>
      <div class="last"></div>
    </div>
    <div cdkDropList class="dragdrop-list" (cdkDropListDropped)="drop($event)">
      @for (
        ingredient of ingredients;
        track trackByIngredient(i, ingredient);
        let i = $index
      ) {
        <div cdkDrag class="ingredient">
          <div cdkDragHandle mat-icon-button class="action-button drag">
            <mat-icon aria-hidden="true">drag_indicator</mat-icon>
          </div>
          <recipe-ingredients-line
            class="ingredient-line-component"
            #nodeInput
            [calcBasis]="calcBasis"
            [isLocked]="isLocked"
            [ingredientsAuto]="ingredientsAuto"
            [recipeIngredient]="ingredient"
            [lang]="lang"
            [showNetQuantity]="showNetQuantity"
            [showIcons]="showIcons"
            [allergensView]="allergensView"
            [additivesView]="additivesView"
            (changed)="
              changed.emit({ data: $event, recipeIngredient: ingredient })
            "
            (addLineEvent)="addIngredient(nodeInput)"
            (updateIngredient)="updateIngredient.emit($event)"
            (updateRecipeIngredient)="updateRecipeIngredient.emit($event)"
            (deleteIngredient)="deleteIngredient($event)"
            (searchIngredients)="searchIngredients.emit($event)"
            (chooseIngredient)="chooseIngredient($event, i + 1)"
            (createIngredient)="createIngredient($event, i + 1)"
          >
          </recipe-ingredients-line>
        </div>
      }
    </div>
    <div class="footer-empty" [class.narrow]="showNetQuantity">
      <div class="spacer-left"></div>
      <div class="line"></div>
      <div class="spacer-right" [class.extra]="!showNetQuantity"></div>
      <div class="last"></div>
    </div>
    <div class="footer" [class.narrow]="showNetQuantity">
      <button
        [disabled]="isLocked"
        class="add-ingredient"
        mat-button
        (click)="addIngredient()"
      >
        {{ "recipes.content.ingredients.headers.add" | transloco }}
      </button>
      <div class="line"></div>
      <div class="spacer" [class.extra]="!showNetQuantity"></div>
      <div class="last"></div>
    </div>
  } @else {
    <spinner></spinner>
  }
</div>
