import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { InterfaceLanguage } from '../../constants/languages';
import { DeepPartial } from '../../Models/generics';
import { RatingChoice } from '../../Models/feedback';
import { ResetPassword, AccountStatusMessage } from '../../Models/models';
import {
  EmailPreferences,
  InterfaceCurrency,
  NutritionUnit,
  SimpleUser,
  User,
} from '../../Models/user';

export const setUser = createAction(
  `[User API] Save user into ngrx store`,
  props<{ user: User; cid?: number }>(),
);

export const patchUserFetchPlansModules = createAction(
  `[Settings container, User API] Patch User, and fetch plans and modules to update them`,
  props<{ user: DeepPartial<User> }>(),
);

export const patchUser = createAction(
  `[User API] Patch User object`,
  props<{ user: DeepPartial<User>; cid?: number; onFulfilled?: () => void }>(),
);

export const setUserError = createAction(
  `[User API] Set User error`,
  props<{ error: Object }>(),
);

export const parseUserError = createAction(
  `[User API] Parse User error`,
  props<{ error: HttpErrorResponse }>(),
);

export const patchUserBusinessDetailsFetchPlans = createAction(
  `[Settings container, Confirm business details dialog] PATCH user's business details and then fetch plans and modules`,
  props<{ user: DeepPartial<User> }>(),
);

export const fetchUser = createAction(`[User API] Fetch user`);

export const emailPreferencesChanged = createAction(
  '[Settings container / Account tab] Email preferences settings were changed',
  props<{ newPreferences: { email_preferences: Partial<EmailPreferences> } }>(),
);

export const changeEmail = createAction(
  '[Settings container / Account tab] Email was changed',
  props<{ email: string }>(),
);

export const emailChanged = createAction(
  '[Settings container / Account tab] User email was changed',
  props<{ cid: number }>(),
);

export const patchUserError = createAction(
  '[Settings container / User API] An error occured during PATCHing a User object',
  props<{ error: HttpErrorResponse; cid: number }>(),
);

export const countryChanged = createAction(
  `[Settings container / User API] User's country changed`,
  props<{ cid: number }>(),
);

export const changeLanguage = createAction(
  '[Settings container / Account tab] Change language',
  props<{ language: InterfaceLanguage }>(),
);

export const changeCurrency = createAction(
  '[Settings container / Account tab] Change currency',
  props<{ currency: InterfaceCurrency }>(),
);

export const currencyChanged = createAction(
  '[Settings container / Account tab] Currency was changed',
  props<{ cid: number }>(),
);

export const openUserDataPdf = createAction(
  `[Settings container / Account tab] Open user's data as PDF`,
);

export const changeUnits = createAction(
  '[Settings container / Account tab] Change units',
  props<{
    units: {
      units_imperial?: boolean;
      units_metric?: boolean;
      units_us?: boolean;
    };
  }>(),
);

export const changeNutritionValueUnits = createAction(
  '[Settings container / Account tab] Change nutrition units',
  props<{
    enabled_nutrition_values: NutritionUnit[];
  }>(),
);

export const changeSalesTax = createAction(
  '[Settings container / Account tab] Change sales tax rates',
  props<{
    vat_rate?: number;
    vat_rate_full?: number;
  }>(),
);

export const deleteAccount = createAction(
  '[Settings container / Account tab] Delete account',
);

export const leaveFeedback = createAction(
  '[Account deletion] Leave feedback',
  props<{ email?: string; message: string; rating: RatingChoice }>(),
);

export const changeInvoicingEmail = createAction(
  '[Settings container / Billing tab / Invoices table] Invoicing email was changed',
  props<{ invoicing_email: string }>(),
);

export const uploadUserLogo = createAction(
  `[Settings container / Profile tab] Upload user logo`,
  props<{ file: File; itemAlias: string; cid?: number }>(),
);

export const fetchOrganisationUsers = createAction(
  `[Locations API] Get organisation users`,
  props<{ params?: any }>(),
);

export const setOrganisationUsers = createAction(
  `[Locations API] Set organisation users locally`,
  props<{ payload: SimpleUser[] }>(),
);

export const changePassword = createAction(
  '[Settings container / Account tab] Password update was requested',
  props<{ passwordData: ResetPassword }>(),
);

export const setStatusBar = createAction(
  '[Status] Set status bar',
  props<{ data: boolean }>(),
);

export const setStatusBarEmail = createAction(
  '[Status] Set status bar email',
  props<{ data: boolean }>(),
);

export const setStatusBarMobile = createAction(
  '[Status] Set status bar mobile',
  props<{ data: boolean }>(),
);

export const handleStatusMessages = createAction(
  '[Status] Handle status messages locally',
  props<{ user: User }>(),
);

export const handleAccountMessages = createAction(
  '[Status] Handle account status messages locally',
  props<{ user: User }>(),
);

export const handleMenuLimitMessages = createAction(
  '[Status] Handle menus status messages locally',
  props<{ user: User }>(),
);

export const handleTemplateLimitMessages = createAction(
  '[Status] Handle template messages locally',
  props<{ user: User }>(),
);

export const setStatusMain = createAction(
  '[Status] Set main status locally',
  props<{ main: AccountStatusMessage }>(),
);

export const setStatusMenus = createAction(
  '[Status] Set status menus locally',
  props<{ menus: AccountStatusMessage }>(),
);

export const setStatusTemplates = createAction(
  '[Status] Set status templates locally',
  props<{ templates: AccountStatusMessage }>(),
);

export const incrementAiUsage = createAction(
  '[Status] Increment AI credits usage',
  props<{
    amount: number;
    credit: 'allergens' | 'dishes' | 'recipes' | 'menu_analysis';
  }>(),
);
