import { langsExtended } from 'src/app/shared/constants/languages';

import { Additive, Allergen } from './declarations';
import { SimpleDish } from './dish';
import { Ingredient } from './ingredients';
import { BaseDescriptionExtended, BaseNameExtended } from './models';

export class Nutrition {
  carbs: number;
  carbs_sugar: number;
  energy: number;
  fat: number;
  fat_saturated: number;
  fibres: number;
  protein: number;
  salt: number;
}

export class RecipeNutrition {
  nutrition: Nutrition;
  missing_data: {
    unit_missing: number;
    quantity_missing: number;
  };
}

export class RecipeCategory extends BaseNameExtended {
  id: number;
  url: string;

  public constructor(init?: Partial<RecipeCategory>) {
    super();
    Object.assign(this, init);
  }
}

export class DishRecipe extends BaseNameExtended {
  id: number;
  url: string;

  preview: string;
  servings: number;

  ingredients_list?: SimpleRecipeIngredient[];
}

export class RecipeCost {
  cost: number;
  cost_missing: number;
  unit_missing: number;
  quantity_missing: number;
}

export class Recipe extends DishRecipe {
  description_de: string;
  description_it: string;
  description_en: string;
  description_fr: string;
  description_es: string;

  diets: string;

  categories: number[];
  categories_detail?: RecipeCategory[];

  chef: string;
  image: string;
  ingredients: string;
  dishes: string;
  steps: string;

  show_net_quantity: boolean;
  missing_data: {
    unit_missing: number;
    quantity_missing: number;
  };

  nutrition: string;
  cost: string;
  allergens: string;
  additives: string;

  privileges: {
    edit_recipe: boolean;
    delete_recipe: boolean;
    change_location: boolean;
  };
  created_by_role?: number;
  created_by_name: string;
  created_by: number;
  location: number;
  location_detail: {
    id: number;
    url: string;
    name: string;
    acronym: string;
  };
  number_ingredients: number;
  number_dishes: number;
  number_steps: number;
  number_derivatives: number;
  custom_nutrition_values: boolean;

  time_preparation?: string;
  time_cooking?: string;
  time_ready?: string;

  energy?: number;

  uncollapse?: boolean;
  name?: string;
  italic?: boolean;
  refetch?: 'all' | RecipeDetail;
  portion_weight: number;
  portion_weight_us: boolean;
}

export class RecipesResult {
  count: number;
  next: string;
  previous: string;
  results: Recipe[];
}

export type RecipeDetail = 'cost' | 'nutrition' | 'allergens' | 'additives';

export type RecipeDetailType = RecipeCost | Nutrition | Allergen[] | Additive[];

export interface RecipeParams extends BaseNameExtended {
  condensed?: true;
  exclude?: number | number[];
  current_menu?: number;
  current_recipe?: number;
  search?: string;
  page_size?: number;
  own?: boolean;
  categories?: number[];
}

export class RecipeDish extends BaseDescriptionExtended {
  url: string;
  id: number;
  dish_detail: SimpleDish;
  dish: number;
  organisation: number;
  location: number;

  public constructor(setTempId = false) {
    super();
    if (setTempId) this.id = Date.now() + Math.floor(Math.random() * 100);
    this.dish_detail = new SimpleDish();
  }
}

export class RecipeStep extends BaseNameExtended {
  id: number;
  url: string;

  description_de: string;
  description_it: string;
  description_en: string;
  description_fr: string;
  description_es: string;

  order: number;
  image: any;

  public constructor(setTempId = false, order = 1) {
    super();
    this.order = order;
    if (setTempId) this.id = Date.now() + Math.floor(Math.random() * 100);
  }
}

export interface RecipeCategoriesParams {
  current_recipe: number;
  ordering: (typeof langsExtended)[number];
}

export class SimpleRecipeIngredient {
  id: number;
  url: string;
  ingredient: number;
  ingredient_detail: Ingredient;
  quantity: number;
  quantity_net: number;
  unit: string;
  weight_loss: number;
  weight_loss_perc: number;
  order: number;
}

export class RecipeIngredient extends SimpleRecipeIngredient {
  recipe: number;

  refetchRecipe?: boolean;

  constructor(recipeIngredient?: any, setTempId = false) {
    super();
    if (recipeIngredient) Object.assign(this, recipeIngredient);
    if (setTempId) this.id = Date.now() + Math.floor(Math.random() * 100);
    if (!this.ingredient_detail) this.ingredient_detail = new Ingredient();
  }
}

export class AIRecipeIngredient {
  name: number;
  quantity: number;
  unit: string;
}

export class AIRecipe {
  recipe_name: string;
  ingredients: AIRecipeIngredient[];
}
