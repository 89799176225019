import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Dish } from 'src/app/shared/Models/dish';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import {
  RecipeIngredient,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';

import { RecipeIngredientsLineComponent } from './recipe-ingredients-line/recipe-ingredients-line.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { SpinnerComponent } from '../../shared/Components/spinner/spinner.component';
import { MatButtonModule } from '@angular/material/button';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'recipe-ingredients',
  templateUrl: './recipe-ingredients.component.html',
  styleUrls: ['./recipe-ingredients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    RecipeIngredientsLineComponent,
    MatButtonModule,
    MatIconModule,
    SpinnerComponent,
    TranslocoPipe,
  ],
})
export class RecipeIngredientsComponent {
  @Input() calcBasis: 'gross' | 'net' = 'gross';
  @Input() ingredients: (RecipeIngredient | SimpleRecipeIngredient)[];
  @Input() lang: InterfaceLanguage;
  @Input() ingredientsAuto: Ingredient[];
  @Input() showNetQuantity: boolean;
  @Input() isLocked: boolean;
  @Input() showIcons: boolean;
  @Input() allergensView: boolean;
  @Input() additivesView: boolean;

  @Output() updateIngredient = new EventEmitter<{
    url: string;
    id: number;
    ingredient: Partial<Ingredient>;
    recipeIngredient: RecipeIngredient;
  }>();
  @Output() updateRecipeIngredient = new EventEmitter<{
    url: string;
    id: number;
    recipeIngredient: Partial<RecipeIngredient>;
    onFulfilled: () => void;
  }>();
  @Output() deleteIngredientEvent = new EventEmitter<RecipeIngredient>();
  @Output() addIngredientEvent = new EventEmitter();
  @Output() searchIngredients = new EventEmitter<string>();
  @Output() chooseIngredientEvent = new EventEmitter<{
    id: number;
    ingredient_id: number;
    order: number;
  }>();
  @Output() createIngredientEvent = new EventEmitter<{
    ingredient: Partial<Ingredient>;
    id: number;
    order: number;
  }>();
  @Output() changed = new EventEmitter<{
    data: {
      data: Dish | Ingredient;
      type: string;
    };
    recipeIngredient: SimpleRecipeIngredient | RecipeIngredient;
  }>();
  @Output() sortIngredients = new EventEmitter<
    { id: number; order: number }[]
  >();

  @ViewChildren(RecipeIngredientsLineComponent, {
    read: RecipeIngredientsLineComponent,
  })
  nodeInputs: QueryList<RecipeIngredientsLineComponent>;

  constructor(private ngZone: NgZone) {}

  setFocus = () => {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        if (!this.nodeInputs.last) return undefined;
        const last = this.nodeInputs.last;
        last.focusNameOrQuantity();
      }, 500);
    });
  };

  deleteIngredient(event: any): void {
    this.deleteIngredientEvent.emit(event);
    this.setFocus();
  }

  chooseIngredient(
    data: {
      id: number;
      ingredient_id: number;
    },
    order: number,
  ): void {
    this.chooseIngredientEvent.emit({ ...data, order });
    this.setFocus();
  }

  createIngredient(
    data: {
      ingredient: Partial<Ingredient>;
      id: number;
    },
    order: number,
  ): void {
    this.createIngredientEvent.emit({ ...data, order });
    this.setFocus();
  }

  addIngredient(item?: RecipeIngredientsLineComponent): void {
    if (
      !this.nodeInputs.last ||
      (!this.nodeInputs.last && !this.nodeInputs.last.recipeIngredient) ||
      (!item && this.nodeInputs.last.recipeIngredient.url) ||
      item === this.nodeInputs.last
    ) {
      this.addIngredientEvent.emit();
    }
    this.setFocus();
  }

  public trackByIngredient(
    index,
    item: RecipeIngredient | SimpleRecipeIngredient,
  ): void | number {
    return item.id;
  }

  sortRecipeIngredients(): void {
    const data: { id: number; order: number }[] = this.ingredients.map(
      (ingredient, index) => ({
        id: ingredient.id,
        order: index + 1,
      }),
    );
    this.sortIngredients.emit(data);
  }

  drop(event: CdkDragDrop<{ id: number; name: string }[]>) {
    moveItemInArray(this.ingredients, event.previousIndex, event.currentIndex);
    this.sortRecipeIngredients();
  }
}
