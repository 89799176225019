import { Action, createReducer, on } from '@ngrx/store';
import { Affiliate } from 'src/app/shared/Models/user';
import { EmailVerification } from 'src/app/shared/Models/authentication';
import { Invitee } from 'src/app/shared/Models/invitee';
import * as AuthActions from './auth.actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  accept_button_spinner: boolean;
  affiliates: Affiliate[];
  affiliates_error: boolean;
  email_preferences_error: string;
  email_verification: EmailVerification | {};
  email_verified: boolean;
  invitee: Invitee;
  invitee_spinner: boolean;
  logged_in: boolean;
  login_spinner: boolean;
  magic_link: boolean;
  next: string;
  register_spinner: boolean;
  reset_spinner: boolean;
  show_error: boolean;
  show_existing_email_error: boolean;
  show_reactivation_done: boolean;
  show_reactivation_error: string;
}

const initialState: AuthState = {
  accept_button_spinner: false,
  affiliates: null,
  affiliates_error: false,
  email_preferences_error: null,
  email_verification: null,
  email_verified: null,
  invitee: null,
  invitee_spinner: true,
  logged_in: false,
  login_spinner: false,
  magic_link: false,
  next: null,
  register_spinner: false,
  reset_spinner: false,
  show_error: false,
  show_existing_email_error: false,
  show_reactivation_done: false,
  show_reactivation_error: null,
};

const _authReducer = createReducer(
  initialState,
  on(AuthActions.setAffiliates, (state, { affiliates }) => ({
    ...state,
    affiliates,
    affiliates_error: false,
  })),
  on(AuthActions.setAffiliatesError, (state, { error }) => ({
    ...state,
    affiliates: null,
    affiliates_error: error,
  })),
  on(AuthActions.setEmailPreferencesError, (state, { message }) => ({
    ...state,
    email_preferences_error: message,
  })),
  on(AuthActions.setEmailRemoteCheck, (state, { verificationData }) => ({
    ...state,
    email_verification: verificationData,
  })),
  on(AuthActions.setEmailVerified, (state, { success }) => ({
    ...state,
    email_verified: success,
  })),
  on(AuthActions.setLoggedIn, (state, { isLoggedIn }) => ({
    ...state,
    logged_in: isLoggedIn,
    email_preferences_error: null,
  })),
  on(AuthActions.setLoginSpinnerState, (state, { loading }) => ({
    ...state,
    login_spinner: loading,
  })),
  on(AuthActions.logoutUser, () => ({
    ...initialState,
  })),
  on(AuthActions.setNextPage, (state, { nextPage }) => ({
    ...state,
    next: nextPage,
  })),
  on(AuthActions.setMagicLink, (state, { isMagicLink }) => ({
    ...state,
    magic_link: isMagicLink,
  })),
  on(AuthActions.setReactivationDone, (state, { done }) => ({
    ...state,
    show_reactivation_done: done,
  })),
  on(AuthActions.setReactivationRestoreError, (state, { message }) => ({
    ...state,
    show_reactivation_error: message,
  })),
  on(AuthActions.setRegisterButtonState, (state, { loading }) => ({
    ...state,
    register_spinner: loading,
  })),
  on(AuthActions.setResetPasswordButtonState, (state, { loading }) => ({
    ...state,
    reset_spinner: loading,
  })),
  on(AuthActions.setAcceptButton, (state, { loading }) => ({
    ...state,
    accept_button_spinner: loading,
  })),
  on(AuthActions.setInvitee, (state, { invitee }) => ({
    ...state,
    invitee: invitee,
  })),
  on(AuthActions.setInviteeSpinner, (state, { loading }) => ({
    ...state,
    invitee_spinner: loading,
  })),
  on(AuthActions.setExistingEmailError, (state, { error }) => ({
    ...state,
    show_existing_email_error: error,
  })),
);

export function authReducer(state: AuthState, action: Action) {
  return _authReducer(state, action);
}
